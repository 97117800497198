import React from 'react';
import './about.css';

const matches = [
    {
     baan: 1,
     trainer: 'Jilles',
     tijd: '19.10-19.30'
    },
    {
    baan: 1,
    trainer: 'Jilles',
    tijd: '19.50-20.10'
    },
    {
    baan: 1,
    trainer: 'Robbert',
    tijd: '20.30-20.50'
    },
    {
    baan: 9,
    trainer: 'Hans',
    tijd: '21.10-21.30'
    },
   ];

const matchesList = matches.map(function(match,index) {
    return    (   

        <div key={index}>
            <div className="matchTile">
                <p>Baan: {match.baan}</p>
                <p>Trainer: {match.trainer}</p>
                <p>Tijd: {match.tijd}</p>
            </div>
        </div>
    )
  });

const About = () =>
    {

        return(       
            <div>
            <h2>Wedstrijden</h2>

            {matchesList}

            </div>
        );
    }
export default About;