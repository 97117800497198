import React from 'react';
import './Login.css';

const endpoint = process.env.REACT_APP_WAPI_ENDPOINT

async function loginUser(credentials) {
  try {
    const response = await fetch(endpoint + 'Account/Login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials),
      credentials: 'include' // Ensure cookies are included in the request
    });

    if (!response.ok) {
      throw new Error('Login failed');
    }

    // Check if the response has a body
    const text = await response.text();
    return text ? JSON.parse(text) : {};
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}


 
const Login = () => {

  const handleSubmit = async e => {
    e.preventDefault();
    
    const username = e.target.username.value;
    const password = e.target.password.value;
  
    try {
      const response = await loginUser({ username, password });
      console.log('Login successful:', response);
      // Handle successful login, e.g., update the state, redirect the user, etc.
      } catch (error) {
      console.error('Error during login:', error.message);
      // Handle error, e.g., show an error message to the user
    }
  }

  return(
    <div className="login-wrapper">
      <h1>Please Log In</h1>
      <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="username">Username:</label>
        <input type="text" id="username" name="username" required />
      </div>
      <div>
        <label htmlFor="password">Password:</label>
        <input type="password" id="password" name="password" required />
      </div>
      <button type="submit">Login</button>
      </form>
    </div>
  )
}

export default Login;