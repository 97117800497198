import DesktopNavigation from './DesktopNavigation';
import React from 'react'

const NavBar = () => {
    return(
        <div>
            <DesktopNavigation />
        </div>
    )
}

export default NavBar;