import Header from './Components/Header/Header';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './Components/Dashboard/Dashboard';
import Preferences from './Components/Preferences/Preferences';
import Login from './Components/Login/Login';
import About from './Components/About/About';
import { AuthProvider } from './Components/Authentication/AuthContext';
import ProtectedRoute from './Components/Authentication/ProtectedRoute';
import './App.css';
import React from 'react';

function App() {
  return (
    <div>
      <div>
        <Header/>  
      </div>
      <div className="wrapper">
      <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/preferences"
            element={<ProtectedRoute element={<Preferences />} roles={['User', 'Admin','SuperAdmin']} />}
          />
          <Route
            path="/dashboard"
            element={<ProtectedRoute element={<Dashboard />} roles={['User', 'Admin','SuperAdmin']} />}
          />
          <Route path="/about" element={<About />} />
        </Routes>
      </Router>
    </AuthProvider>     
      </div>
    </div>
  );
}

export default App;
