import NavBar from './../NavBar/NavBar';
import './Header.css';
import logo from './logo.svg';
import MobileNavigation from '../NavBar/MobileNavigation';
import React from 'react'

const Header = () =>{
    return(
        <div>
        <header className="header">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="title">
          <h1>Botched</h1>
        </div>
        <MobileNavigation/>
      </header>
      <NavBar/>
      </div>
    )
}

export default Header;