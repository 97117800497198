import React, {createContext,useState,useEffect} from 'react';

const AuthContext = createContext();

const endpoint = process.env.REACT_APP_WAPI_ENDPOINT;

const AuthProvider = ({ children  }) => 
    {

const [isAuthenticated, setIsAuthenticated] = useState(false);
const [roles, setRoles] = useState([]);
const [loading, setLoading] = useState(true);

useEffect(() => {
    const checkAuth = async () => {
      try {
      
        const response = await fetch(endpoint + 'Account/Authentication', {
          method: 'GET',
          credentials: 'include', // Ensure cookies are sent with the request
        });

        if (response.status === 200) {
          setIsAuthenticated(true);
          const data = await response.json();
          setRoles(data.roles || []);

        } else {
          setIsAuthenticated(false);
          setRoles([]);
        }
      } catch (error) {
        setIsAuthenticated(false);
        setRoles([]);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, roles,loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
