import './NavBar.css';
import React from 'react'

const NavLinks = ({isClicked, closeMenu}) => {
    return(
        <div className="NavigationBar">
        <nav className="NavLinks">           
        <ul>
            <li>
                <a href="/" onClick={isClicked && closeMenu}>Home</a>
            </li>
            <li>
                <a href="/dashboard" onClick={isClicked && closeMenu}>Dashboard</a>
            </li>
            <li>
                <a href="/preferences" onClick={isClicked && closeMenu}>Preferences</a>
            </li>
            <li>
                <a href="/about" onClick={isClicked && closeMenu}>About</a>
            </li>
            <li>
                <a href="/login" onClick={isClicked && closeMenu}>Login</a>
            </li>
        </ul>
    </nav>
    </div>
    )
}

export default NavLinks;