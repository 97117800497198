import NavLinks from "./NavLinks";
import './NavBar.css';
import React from 'react'

const DesktopNavigation = () =>{
    return(
        <nav className="DesktopNavigation">
            <NavLinks />
        </nav>
    )
}

export default DesktopNavigation;