import React from 'react';

const Dashboard = () =>
    {

        return(
            <h2>Dashboard</h2>
        );
    }


export default Dashboard;